import { useState } from 'react';
import { CallBackProps, STATUS } from 'react-joyride';
import { SVGIcon } from '../../../assets/SVGIcon';

export interface JoyrideStepInterface {
    target: string;
    content: string | JSX.Element;
    disableBeacon?: boolean;
}

export const JOYRIDE_STEPS = {
    dashboardNavigation: {
        target: '#dashboard-navigation',
        content: 'Here you can navigate between the different open dashboards.',
        disableBeacon: true,
    },
    assistantAi: {
        target: '#assistant-ai',
        content: 'Here you can ask on how to find certain metrics in this dashboard.',
    },
    buChoice: {
        target: '.dashboard-bu-choice',
        content: 'BU Choice.',
    },
    navSection: {
        target: '.dashboard-nav-section',
        content: 'Navigation Section.',
    },
    navSecondSection: {
        target: '.dashboard-nav-second-section',
        content: 'Other Navigation Section.',
    },
    temporalSection: {
        target: '.dashboard-temporal-section',
        content: 'Temporal Section.',
    },
    refSection: {
        target: '.dashboard-ref-section',
        content: 'Reference Section.',
    },
    filterSection: {
        target: '.dashboard-filter-section',
        content: 'Filter Section.',
    },
    passengersSection: {
        target: '.dashboard-passengers-section',
        content: 'Passengers Section.',
    },
    passengersGraphSection: {
        target: '.dashboard-passengers-graph-section',
        content: (
            <div>
                <h3 className="font-bold mb-2">While hovering through this section...</h3>
                <p className="mb-3">You'll have access to additional functions on top right of this graph.</p>
                <div className="flex gap-2">
                    <div className='border p-1'><SVGIcon icon='POWER_BI_DOUBLE_ARROW'/></div>
                    <p className="mt-2 text-sm text-gray-600">This feature allow you to...</p>
                </div>
            </div>
        ),
    },
    airlinesSection: {
        target: '.dashboard-airlines-section',
        content: 'Airlines Section.',
    },
    airlinesLine: {
        target: '.dashboard-airlines-line',
        content: 'Airlines Line.',
    },
    routeSection: {
        target: '.dashboard-route-section',
        content: 'Route Section.',
    },
    routeLine: {
        target: '.dashboard-route-line',
        content: 'Route Section.',
    },
    assistantAiPresentation: {
        target: '#assistant-ai-presentation',
        content: 'Get some inspiration on what you could ask.',
    },
    assistantAiPrompt: {
        target: '#assistant-ai-prompt',
        content: 'Click on the pre-written prompt and start discovering the metrics..',
    }
};

export const useJoyride = (availableSteps: JoyrideStepInterface[]) => {
    const [runTour, setRunTour] = useState(true);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            setRunTour(false);
        }
    };

    const joyrideProps = {
        steps: availableSteps,
        run: runTour,
        continuous: true,
        showProgress: true,
        showSkipButton: true,
        callback: handleJoyrideCallback,
        styles: {
            options: {
                primaryColor: '#0D3D62',
            },
        },
    };

    return joyrideProps;
};
import { useEffect, useState } from "react";

interface SessionState {
    startTime: string | null;
    elapsedTime: number | null;
    status: 'active' | 'expired' | 'not_found' | 'error' | 'connection_closed';
    error: string | null;
}

export const useStreamSession = (sessionId: string | null, token: string | null) => {
    const [sessionState, setSessionState] = useState<SessionState>({
        startTime: new Date().toISOString(),
        elapsedTime: 0,
        status: 'active',
        error: null
    });

    useEffect(() => {
        if (!sessionId || !token) return;

        let abortController = new AbortController();
        let active = true;

        const monitorSession = async () => {
            try {
                const response = await fetch(`/api/streamlit/stream-session/${sessionId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    signal: abortController.signal
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const reader = response.body?.getReader();
                if (!reader) throw new Error('No reader available');

                const decoder = new TextDecoder();
                let buffer = '';

                while (active) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    buffer += decoder.decode(value, { stream: true });
                    const lines = buffer.split('\n');
                    buffer = lines.pop() || '';

                    for (const line of lines) {
                        // Ignore empty lines
                        if (!line.trim()) continue;

                        // Parse SSE format
                        if (line.startsWith('data: ')) {
                            try {
                                const data = JSON.parse(line.slice(6));
                                console.log('Stream data received:', data); // Debug log

                                if (!active) break;

                                // Mise à jour immédiate de l'état
                                setSessionState(prev => {
                                    const newState = {
                                        ...prev,
                                        status: data.status,
                                        error: data.error || null,
                                    };
                                    console.log('Setting new session state:', newState); // Debug log
                                    return newState;
                                });
                            } catch (error) {
                                console.error('Error parsing SSE data:', error);
                            }
                        }
                    }
                }
            } catch (error) {
                if (!active) return;

                console.error('Stream error:', error);
                setSessionState(prev => ({
                    ...prev,
                    status: 'error',
                    error: error instanceof Error ? error.message : 'Unknown error'
                }));
            }
        };

        monitorSession();

        return () => {
            active = false;
            abortController.abort();
        };
    }, [sessionId, token]);

    return sessionState;
};
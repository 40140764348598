import { useEffect, useState } from "react"
import { DataTableQuality } from "./DataTable"
import { useDataGouv } from "./providers/DataGouvProvider"
import { PermissionCategory, useDataQuality } from "./hooks/useDataQuality"
import { useActiveTabs, useAuth } from "../../providers"
import { SelectOptions, SelectOptionsDataGouv } from "../DocumentTranslator/SelectedOptions"
import { Ingestion } from "./Ingestion"
import { Tile } from "powerbi-client"
import { DataGouvHome } from "./DataGouvHome"
import { SVGIcon } from "../../assets"
import { Referentiel } from "./Referentiel"
import { DataLineage } from "./DataLineage"
import { AuthDenied } from "./AuthDenied"

interface BUProps {
    title: string
    tabId: number
}

const navOpt = [
    {
        id: 'Quality',
        title: "Quality",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Ingestion',
        title: "Ingestion",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Referentiel',
        title: "Referentiel",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Lineage',
        title: "Data Lineage",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    }
]

const listUseCase = ["Traffic","Finance","Retail","Communication","Carpark","Ops","HR","External","Predict"]
  

export const BUMainApp: React.FC<BUProps> = ({title, tabId}) => {
    const {buSelected, setBuSelected, dateSelected, setUseCase ,setDateSelected, buOptions, dateOptions, getQualityDetailForBu, app, setApp, useCase, userPermissions, loaderIngestionData, loaderReferentielData,loaderLineageData, authorisedApps, setAuthorisedApps} = useDataGouv()
    const {token} = useAuth()
    const [dataForTable, setDataForTable] = useState({
        mapping_coverage: [],
        data_integrity: [],
        file_integrity: [],
        file_not_received: [],
    });    
    const [filterStatus, setFilterStatus] = useState<string | null>(null)
    const [loaderTable, setLoaderTable] = useState<boolean>(false)
    const [selectedUniqueFileType, setSelectedUniqueFileType] = useState<string | null>(null);
    const {openTab, tabState} = useActiveTabs()
    const [authorised, setAuthorised] = useState<boolean>(false)
    // const [authorisedApps, setAuthorisedApps] = useState<string[]>([])
    const [filteredNavOpt, setFilteredNavOpt] = useState(navOpt);
    const [validBus, setValidBUs] = useState<string[] | null>(null)
    

    const getValidBus = (use_case: string) => {
        if (!userPermissions) return;
    
        const sanitizedUseCase = use_case.replace(/\s+/g, "").toUpperCase();
        const valid = Object.keys(userPermissions).filter((bu) => {
            const permissions = userPermissions[bu];
            return Object.values(permissions).some((useCases) =>
                useCases.some((uc: string) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase)
            );
        });
    
        setValidBUs(valid);
    };

    useEffect(()=>{
        console.log(validBus)
        console.log(authorisedApps)
    },[validBus, authorisedApps])

    const checkUserPermissions = (use_case: string) => {
        if (!userPermissions || !buSelected) return;
        const sanitizedUseCase = use_case.replace(/\s+/g, "").toUpperCase();
    
        const rightsForBu = userPermissions[buSelected] ?? []
    
        const hasUseCase = Object.values(rightsForBu).some((useCases) =>
            useCases.some((uc: string) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase)
        );

        setAuthorised(hasUseCase);
        getValidBus(sanitizedUseCase);
    
        const appsAuthorised = Object.keys(rightsForBu).filter((category) => {
            const key = category as keyof PermissionCategory;
            return rightsForBu[key].some((uc) => uc.replace(/\s+/g, "").toUpperCase() === sanitizedUseCase);
        });
        setAuthorisedApps(appsAuthorised);
    };
    

    useEffect(() => {
        if (tabId === tabState.activeTabId) { 
          setUseCase(title);
        }
    }, [title, tabState.activeTabId, tabId]);

    useEffect(()=>{
        if (tabId === tabState.activeTabId && userPermissions && buSelected) { 
            checkUserPermissions(title)
        }
    },[title, tabId, tabState.activeTabId, buSelected])

    useEffect(() => {
        if(!authorisedApps) return
        const updatedNavOpt = navOpt.filter((elem) => authorisedApps.includes(elem.id));
        setFilteredNavOpt(updatedNavOpt!);
    }, [authorisedApps, tabState.activeTabId]);

    // useEffect(()=>{
    //     if(!authorisedApps) return
    //     if(authorisedApps.includes(app)) return
    //     setApp(authorisedApps![0])
    // },[tabState.activeTabId, authorisedApps])
    

    const defaultTab = {
        id: 50,
        appId: "GenAI/SecureChatGPT",
        title: "Overview",
        icon: <SVGIcon size="16px" icon="AIRLINEID_BU_ICON" />,
        description: [],
        app: () => <DataGouvHome/>,  
    }

    const setUpTable = async() =>{
        if(!authorisedApps?.includes('Quality')) return
        setLoaderTable(true)
        const data = await getQualityDetailForBu(buSelected!,title.toLocaleUpperCase(), parseInt(dateSelected!))
        setDataForTable(data)
        setLoaderTable(false)
    }

    useEffect(()=>{
        if(app === 'Quality') setUpTable()
    },[buSelected,dateSelected,app])

    return(
        <div className="flex flex-col h-full items-center">
            <div className="flex flex-col bg-white p-5 items-center justify-center text-text-primary-light w-full">
                <div className="flex flex-row w-full justify-start">
                    <div className="flex flex-row gap-4 w-full"> 
                        <div className="flex flex-row  h-[40px] w-fit items-center border rounded-md text-black text-sm">
                            <button className="flex font-semibold items-center mr-4" onClick={() => openTab(defaultTab)}>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="rotate-90"
                                width="18"
                                height="18"
                                fill={'#EEEEEE'}
                                viewBox="0 0 24 24"
                                >
                                    <path
                                    fill='black'
                                    fillRule="evenodd"
                                    d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                                    clipRule="evenodd"
                                    ></path>
                                </svg> 
                                Overview
                            </button>
                            <div className="flex items-center rounded-md h-full w-fit cursor-pointer">
                                {filteredNavOpt.map((e, i) => {
                                    const isCurrentApp = e.id === app;
                                    const bgColorClass = isCurrentApp ? `bg-[#D0D5DD] bg-opacity-50 text-[#004289]` : 'text-[#344054]';
                                    const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                                    return (
                                        <div key={i} className={`h-full border-[0.7px] flex items-center text-center px-5 py-1.5 ${bgColorClass} ${transitionClass}`} onClick={() => setApp(e.id)}>
                                            <h3 className="font-semibold">{e.title.replaceAll(' ', '\u00A0')}</h3>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="flex flex-row gap-4 items-center w-full justify-end">
                            <p className="text-[#667085] font-medium">BU Selection:</p>
                            <SelectOptionsDataGouv
                                options={buOptions}
                                defaultValue="-"
                                renderOption={(method) => ({ value: method, label: method })}
                                selectedValue={buSelected!}
                                onChange={(e) =>{if(loaderIngestionData || loaderLineageData || loaderReferentielData) return;setBuSelected(e.target.value)}}
                            />
                            {app === 'Quality' && 
                            <>
                                <h1 className="text-[#667085] font-medium">Year to Date:</h1>
                                <SelectOptionsDataGouv
                                    options={dateOptions}
                                    defaultValue="-"
                                    renderOption={(method) => ({ value: method, label: method })}
                                    selectedValue={dateSelected!}
                                    onChange={(e) => setDateSelected(e.target.value)}
                                    isCalendar={true}
                                />
                            </>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-start p-7 -mb-3 mt-1  gap-2">
                <p className="font-trendaSemiBold text-2xl w-full text-black">{app} {(buSelected === 'SSA') && (useCase?.toLocaleUpperCase() === 'FINANCE') ? 'SSA/AMA' : buSelected} - {title}</p>
            </div>
            {authorised ? 
                <div className={`p-4 w-full ${app === 'Quality' ? '' : 'p-4'}`}>
                    {app === 'Quality' && (
                        <DataTableQuality
                            selectedUniqueFileType={selectedUniqueFileType}
                            setSelectedUniqueFileType={setSelectedUniqueFileType}
                            data={dataForTable}
                            loader={loaderTable}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                        />
                    )}
                    {app === 'Ingestion' && <Ingestion />}
                    {app === 'Referentiel' && <Referentiel />}
                    {app === 'Lineage' && <DataLineage />}
                </div>
                :
                <div className="p-4 w-full">
                    <AuthDenied validBus={validBus!}/>
                </div>
            }
        </div>
    )
}
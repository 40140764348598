import { useState } from "react"
import { Dropzone } from "./Dropzone"

interface DropzoneProps {
    setFiles: React.Dispatch<React.SetStateAction<File[] | []>>;
    files: File[]
}

export const DropdownDropzone:React.FC<DropzoneProps> = ({files,setFiles}) =>{
    const [dropdownOpen, setDropdownOpen] = useState(true)

    return(
        <div className="">
            <div onClick={()=> setDropdownOpen(!dropdownOpen)} className={`flex flex-col bg-[#ECF5FF] text-[#004289] px-3 py-2 justify-center rounded-md cursor-pointer`}>
                <div className="flex flex-row items-center gap-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=""
                        width="18"
                        height="18"
                        fill="#004289"
                        viewBox="0 0 24 24"
                        >
                        <path
                            fill="#004289"
                            fillRule="evenodd"
                            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                    <p className="font-trenda">Multiple files loader</p>
                </div>
            </div>
            {dropdownOpen && 
                <div className={`flex border border-[#EEEEEE] rounded-md bg-white items-center justify-center transition ease-out ${dropdownOpen ? 'h-[120px]' : 'h-[0px]'}`}>
                    <Dropzone setFiles={setFiles} files={files}/>
                </div>
            } 
        </div>
    )
}
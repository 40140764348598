import React, { useEffect } from 'react';
import { FileData, IngestionData } from '../hooks/useDataIngestion';
import { ReferentielData } from '../hooks/useReferentiel';
import { useDataGouv } from '../providers/DataGouvProvider';
interface SelectorFileTypeProps {
  options: IngestionData | ReferentielData | null
  buName: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  setSelectedFileType: React.Dispatch<React.SetStateAction<string | null>>;
  value?: string | null


}
export const SelectorFileType: React.FC<SelectorFileTypeProps> = ({ options, buName, onChange, setSelectedFileType,value}) => {
  const { buSelected, ingestionData, loaderIngestionData } = useDataGouv();
  
  const buOptions = buSelected && ingestionData && ingestionData![buSelected!] || [];
  const fileTypeOptions = Array.from(
    new Set(
      buOptions
        .filter((file) => file.linked === file.file_type || !file.linked || file.file_type === 'CAPEX_FORECAST' || file.linked === 'CPXFORECAST')
        .map((option) => option.file_type)
    )
  );
  useEffect(() => {
    if (!value && fileTypeOptions.length > 0) {
      setSelectedFileType(fileTypeOptions[0]);
    }
  }, [value, fileTypeOptions]);

  return (
    <select className="border border-[#DDDDDD] text-black p-1 font-medium text-sm rounded-md focus:outline-none" value={value || ""} onChange={onChange}>
      {buOptions.length > 0 ? (
        fileTypeOptions.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))
      ) : (
        <option disabled>No options available</option>
      )}
    </select>
  );
};

export const SelectorFileTypeRef: React.FC<SelectorFileTypeProps> = ({ options, buName, onChange, setSelectedFileType,value}) => {
  const { buSelected, dataReferentiel, loaderIngestionData } = useDataGouv();
  
  const buOptions = buSelected && dataReferentiel &&  dataReferentiel![buSelected!] || [];
  const fileTypeOptions = Array.from(new Set(buOptions.map((option) => option.file_type)));
  
  useEffect(() => {
    if (!value && fileTypeOptions.length > 0) {
      setSelectedFileType(fileTypeOptions[0]);
    }
  }, [value, fileTypeOptions]);

  return (
    <select className="border border-[#DDDDDD] text-black p-1 font-medium text-sm rounded-md focus:outline-none" value={value || ""} onChange={onChange}>
      {buOptions.length > 0 ? (
        fileTypeOptions.map((option, index) => (
          <option value={option} key={index}>
            {option}
          </option>
        ))
      ) : (
        <option disabled>No options available</option>
      )}
    </select>
  );
}

interface SelectorFileVersionProps {
  options?: any;
  buName: string;
  year: string;
  disabled: boolean;
  version?: string;
  setSelectedVersion?: React.Dispatch<React.SetStateAction<string>>;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  versions: string[];
}


  export const SelectorFileVersion: React.FC<SelectorFileVersionProps> = ({ buName, year, disabled, version, onChange, setSelectedVersion, versions }) => {

  const isSCA = versions.some(elem => elem === 'KOS')
  const isSSA = versions.some(elem => elem === 'SSA')

  useEffect(()=>{
    console.log(versions)
    if(setSelectedVersion && versions.length > 0){
      setSelectedVersion(versions[0])
    }
  },[isSCA, isSSA])

  if (disabled && version) {
    return (
      <select className="border border-[#DDDDDD] text-black p-1 rounded-md focus:outline-none opacity-50" disabled>
        <option value={version}>{version} {!isSCA && !isSSA && year}</option>
      </select>
    );
  }

  return (
    <select className={`border border-[#DDDDDD] text-black p-1 rounded-md focus:outline-none ${disabled && 'opacity-50'}`} disabled={disabled} onChange={onChange}>
      {versions.length > 0 ? (
        versions.map((version, index) => (
          <option value={version} key={index}>
            {version} {!isSCA && !isSSA && year}
          </option>
        ))
      ) : (
        <option disabled>No versions available</option>
      )}
    </select>
  );
};

import React, { SetStateAction, useEffect, useState } from 'react';
import { SelectOptions, SelectOptionsDataGouv, SelectOptionsQuality } from '../DocumentTranslator/SelectedOptions';
import { useDataGouv } from './providers/DataGouvProvider';
import { useActiveTabs, useAuth } from '../../providers';
import { LoaderCircle } from '../../components';
import { CheckIcon, CrossIcon, DelayedIcon, PendingIcon, RunningIcon } from './SummaryBU';
import { ArrowDownIcon, FileIcon, LinkIcon } from './icons/Icons';
import { formatLastUpdated } from './SummaryBU';
import { LoaderSpinner } from './components/Icons';

interface MenuProps {
  activeTable: string;
  setActiveTable: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
}

export const Menu: React.FC<MenuProps> = ({ activeTable, setActiveTable, options }) => {
  return (
    <div className="flex justify-between items-center w-full bg-white bg-gray-100 p-2 rounded-md mb-6 -mt-3">
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => setActiveTable(option)}
          className={`px-4 py-2 rounded-md flex justify-center w-full ${
            activeTable === option
              ? 'bg-[#ECF5FF] text-[#004289]'
              : 'bg-white text-[#344054]'
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

interface SearchBarProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchBar: React.FC<SearchBarProps> = ({ value, setValue }) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex items-center border rounded-md p-2 w-full bg-white border-[#D0D5DD]">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2"
      >
        <path
          d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
          stroke="#ABC1D0"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <input
        type="text"
        placeholder="Search..."
        className="w-full outline-none text-black placeholder-[#90A7B9] text-sm"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export const transformKeyToActiveTable = (key: string): string => {
  if (key === 'url') return 'Download';
  return key
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

interface DataTableProps {
  data: {
    [key: string]: any[];
  };
  loader: boolean;
  filterStatus: string | null;
  setFilterStatus: React.Dispatch<React.SetStateAction<string | null>>;
  selectedUniqueFileType: string | null;
  setSelectedUniqueFileType: React.Dispatch<React.SetStateAction<string | null>>;
}

export const DataTableQuality: React.FC<DataTableProps> = ({ data, loader, filterStatus, setFilterStatus, selectedUniqueFileType, setSelectedUniqueFileType }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCause, setSelectedCause] = useState<string | null>(null);
  const [selectedDetailedCause, setSelectedDetailedCause] = useState<string | null>(null);
  const [selectedDetailedCauseFix, setSelectedDetailedCauseFix] = useState<string | null>(null);
  const [sortedByDate, setSortedByDate] = useState(false);
  const {app} = useDataGouv()
  const {token} = useAuth()
  const [loadingDownloads, setLoadingDownloads] = useState<{ [url: string]: boolean }>({});
  // const [uniqueFileTypes, setUniqueFileTypes] = useState<string[]>([]);
  // const [filterOptions, setFilterOptions] = useState<string[]>([])
  const { isSidebarOpen, toggleSidebar} = useActiveTabs()


  const [transformedData, setTransformedData] = useState<any[]>([]);

  const {activeTable, setActiveTable} = useDataGouv()


  const menuOptions = ['File Not Received', 'File Integrity', 'Data Integrity', 'Mapping Coverage'];

  const filterColumnsPerMenuOption: { [key: string]: string[] } = {
    'File Not Received': ['file_type'],
    'File Integrity': ['file_type', 'status'],
    'Data Integrity': ['table_name', 'cause'],
    'Mapping Coverage': ['Table Name','Reference Name'],
  };

  const activeFilterColumns = filterColumnsPerMenuOption[activeTable];

  const [filterValues, setFilterValues] = useState<{ [key: string]: string | null }>({});
  const [filterOptions, setFilterOptions] = useState<{ [key: string]: string[] }>({});

  const transformActiveTableToKey = (table: string) => {
    return table
      .toLowerCase()
      .replace(/ /g, '_');
  };
  
  

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const key = transformActiveTableToKey(activeTable);
      setTransformedData(data[key] || []);
    }
  }, [activeTable, data]);

  const [searchBarInput, setSearchBarInput] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(transformedData.length / itemsPerPage);

  useEffect(() => {
    if (transformedData) {
      const newFilterOptions: { [key: string]: string[] } = {};
  
      activeFilterColumns.forEach((column) => {
        const optionsSet = new Set(transformedData.map((item) => item[column] || ''));
        newFilterOptions[column] = Array.from(optionsSet).filter((opt) => opt);
      });
  
      setFilterOptions(newFilterOptions);
    }
  }, [transformedData, activeTable]);

  const filteredData = transformedData.filter((item) => {
    let matchesFilters = true;
    const activeFilterColumns = filterColumnsPerMenuOption[activeTable];
  
    activeFilterColumns.forEach((column) => {
      const selectedValue = filterValues[column];
      if (selectedValue && selectedValue !== 'All' && item[column] !== selectedValue) {
        matchesFilters = false;
      }
    });
    if (searchBarInput.trim()) {
      const searchTerm = searchBarInput.toLowerCase();
      const matchesSearch = Object.values(item).some(
        (value) => value && value.toString().toLowerCase().includes(searchTerm)
      );
      matchesFilters = matchesFilters && matchesSearch;
    }
  
    return matchesFilters;
  });


  const handleDownload = async(url: string) => {
    setLoadingDownloads((prev) => ({ ...prev, [url]: true }));
    try{
      const response = await fetch(`/api/gouvernance/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({gs_url : url})
      });
      if(!response.ok) return
      const signedUrl = JSON.parse(await response.text());
      const a = document.createElement('a');
      a.href = signedUrl;
      a.download = url.split('/').pop() || 'file';
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
    catch(error){
      console.log(error)
      return null
    }
    finally{
      setLoadingDownloads((prev) => ({ ...prev, [url]: false }));
    }
  };

  const sortByDate = (data: any) => {
    return [...data].sort((a, b) => new Date(a.reception_time).getTime() - new Date(b.reception_time).getTime());
  };

  const handleDateHeaderClick = () => {
    setSortedByDate(!sortedByDate);
  };

  const displayedData = sortedByDate ? sortByDate(filteredData) : filteredData;

  const paginatedData = displayedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const getStatusColor = (status: string) => {
    if (status === 'failed') return <CrossIcon/>;
    if (status === 'pending') return <PendingIcon/>;
    if (status === 'success') return <CheckIcon/>;
    if (status === 'running') return <RunningIcon/>;
    if (status === 'delayed') return <DelayedIcon/>;
    return <PendingIcon/>;
  };

  const openModal = (cause: string, detailed_cause?: string, detailed_cause_fix?: string) => {
    setSelectedCause(cause);
    setSelectedDetailedCause(detailed_cause!);
    setSelectedDetailedCauseFix(detailed_cause_fix!);
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
    setSelectedCause(null);
    setSelectedDetailedCause(null);
  };

  const headers = transformedData && transformedData.length > 0 ? Object.keys(transformedData[0]) : [];
  const filteredHeaders = headers.filter((header) => header !== 'detailed_cause' && header !== 'detailed_cause_fix');

  return (
    <div className="w-full p-5">
        <Menu activeTable={activeTable} setActiveTable={setActiveTable} options={menuOptions} />
        <div className={`w-full bg-white rounded-xl`}>
          {transformedData.length !== 0 && <div className="flex w-full">
              <div className='flex flex-row items-center w-full mb-3 overflow-x-scroll no-scrollbar'>
                <div className="flex flex-row gap-5 w-full items-center justify-start p-3">
                {activeFilterColumns.map((column) => (
                  <SelectOptionsQuality
                    key={column}
                    options={filterOptions[column] || []}
                    defaultValue={transformKeyToActiveTable(column)}
                    renderOption={(option) => ({
                      value: option,
                      label: activeTable ? transformKeyToActiveTable(option) : option
                    })}
                    selectedValue={filterValues[column] || ''}
                    onChange={(e) => setFilterValues((prev) => ({ ...prev, [column]: e.target.value }))}
                  />
                ))}
                </div>
                <div className='flex w-full items-center justify-start px-4'>
                  <SearchBar value={searchBarInput!} setValue={setSearchBarInput}/>
                </div>
              </div>
          </div>}
          <div className='w-full border  border-gray-200 overflow-x-scroll no-scrollbar'>
            {loader ? (
              <div className="text-center py-4">
                <LoaderSpinner/>
              </div>
            ) : transformedData.length === 0 ? (
              <div className="text-center py-4 px-2 text-gray-600">
                <p>There are no "{activeTable}" errors to report at this time.</p>
              </div>
            ) : 
          (
            <table className="text-sm w-full">
              <thead className="bg-[#EAECF0] bg-opacity-50 text-[#667085]">
                <tr>
                  {filteredHeaders.map((header, index) => (
                    <th
                      key={index}
                      className="p-4 font-medium text-gray-600 cursor-pointer"
                      onClick={(header === "Uploaded Date" || header === "Reception Time") ? handleDateHeaderClick : undefined}
                    >
                      <div className="flex items-center gap-2">
                        {transformKeyToActiveTable(header).replaceAll(' ', '\u00A0')}
                        <ArrowDownIcon
                          className={`ml-2 ${
                            sortedByDate && (header === "Uploaded Date" || header === "Reception Time")  ? "rotate-180" : ""
                          }`}
                        />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-xs">
                {loader ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                      <LoaderSpinner/>
                    </td>
                  </tr>
                ) :  transformedData.length === 0 ? (
                  <tr>
                    <td colSpan={headers.length} className="text-center py-4">
                      No data available
                    </td>
                  </tr>
                ) : (
                  paginatedData.map((row, rowIndex) => (
                    <tr key={rowIndex} className="border border-gray-200">
                      {filteredHeaders.map((header, colIndex) => {
                        if (header === 'cause') {
                          const causeValue = row['cause'];
                          const hasCause = causeValue && causeValue.length > 0;
                          if (!hasCause) {
                            return (
                              <td key={colIndex} className="p-5 text-gray-800">
                             
                              </td>
                            );
                          }
                          
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div
                                className="cursor-pointer text-[#004289] font-bold flex flex-row gap-3 items-center"
                                onClick={() => openModal(row['cause'], row['detailed_cause'], row['detailed_cause_fix'])}
                              >
                                <LinkIcon/>
                                {row['cause'].replaceAll(' ', '\u00A0').replaceAll('_', ' ')}
                              </div>
                            </td>
                          );
                        }

                        if ((activeTable === 'Mapping Coverage') && header === 'cause' || header === 'Cause') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div className="cursor-pointer" onClick={() => openModal(row['cause'])}>
                                <CrossIcon />
                              </div>
                            </td>
                          );
                        }

                        if (header === 'url') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              <div className="cursor-pointer flex flex-row gap-2 items-center" onClick={()=> handleDownload(row['url'])}>
                                <FileIcon />
                                {row['file_name_sftp']}
                              </div>
                            </td>
                          );
                        }

                        if (header === 'status') {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              {getStatusColor(row[header])}
                            </td>
                          );
                        }

                        if (typeof row[header] === 'string' && row[header].includes('T') && row[header].includes('+')) {
                          return (
                            <td key={colIndex} className="p-5 text-gray-800">
                              {formatLastUpdated(row[header])}
                            </td>
                          );
                        }

                        return (
                          <td key={colIndex} className="p-5 text-gray-800">
                            {row[header] || 'Not available'}
                          </td>
                        );
                      })}
                    </tr>
                  ))                  
                )}
              </tbody>
            </table>
          )}
          </div>
          {transformedData.length !== 0 &&
            <div className="flex justify-between items-center mt-4 px-5 py-3">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                className={`p-2 border border-[#D0D5DD] px-3 py-2 rounded-md text-[#667085] hover:bg-gray-200 ${
                  currentPage === 1 ? 'opacity-60 cursor-not-allowed' : ''
                }`}            >
                <ArrowDownIcon className='rotate-90 scale-[1.2] text-[#0D3D62]'/>
              </button>
              <div className="flex items-center">
                {(() => {
                  const pageNumbers = [];
                  const showLeftEllipsis = currentPage > 4;
                  const showRightEllipsis = currentPage < totalPages - 3;

                  pageNumbers.push(
                    <button
                      key={1}
                      onClick={() => handlePageChange(1)}
                      className={`p-2 w-10 h-10 rounded-md ${
                        currentPage === 1
                          ? 'bg-[#ECF5FF] text-[#004289]'
                          : 'bg-white text-[#667085]'
                      }`}
                    >
                      1
                    </button>
                  );

                  if (showLeftEllipsis) {
                    pageNumbers.push(<span key="left-ellipsis" className="text-[#667085] px-2">...</span>);
                  }

                  const start = Math.max(2, currentPage - 2);
                  const end = Math.min(totalPages - 1, currentPage + 2);

                  for (let i = start; i <= end; i++) {
                    pageNumbers.push(
                      <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={`p-2 w-10 h-10 rounded-md ${
                          currentPage === i
                            ? 'bg-[#ECF5FF] text-[#004289]'
                            : 'bg-white text-[#667085]'
                        }`}
                      >
                        {i}
                      </button>
                    );
                  }

                  if (showRightEllipsis) {
                    pageNumbers.push(<span key="right-ellipsis" className="text-[#667085] px-2">...</span>);
                  }

                  if (totalPages > 1) {
                    pageNumbers.push(
                      <button
                        key={totalPages}
                        onClick={() => handlePageChange(totalPages)}
                        className={`p-2 w-10 h-10 rounded-md ${
                          currentPage === totalPages
                            ? 'bg-[#ECF5FF] text-[#004289]'
                            : 'bg-white text-[#667085]'
                        }`}
                      >
                        {totalPages}
                      </button>
                    );
                  }

                  return pageNumbers;
                })()}
              </div>

              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
                className={`p-2 border border-[#D0D5DD] px-3 py-2 rounded-md text-[#667085] hover:bg-gray-200 ${
                  currentPage === totalPages ? 'opacity-60 cursor-not-allowed' : ''
                }`}            >
                <ArrowDownIcon className='-rotate-90 scale-[1.2] text-[#0D3D62]'/>
              </button>
            </div>
          }
        </div>
        {showModal && (selectedCause || selectedDetailedCause || selectedDetailedCauseFix) && (
          <div className={`fixed top-0 ${isSidebarOpen ? 'left-[10%]' : 'left-[2%]'}  w-full h-full backdrop-blur-sm flex items-center justify-center`}>
            <div className='w-[70%] p-3 flex items-center justify-center'>
              <div className="bg-white border border-gray-200 p-6 rounded-md shadow-md max-h-[400px] 2xl:max-h-[600px] no-scrollbar overflow-scroll">
                <h2 className="text-xl font-bold mb-4">{app} Error Details</h2>
                <p><strong>Cause:</strong> {selectedCause!}</p>
                {selectedDetailedCause && selectedDetailedCause.length > 0 && 
                  <p>
                    <strong>Detailed Cause:</strong> 
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedDetailedCause! }}
                      className="text-justify text-[17px] pl-5 pt-2"
                    />
                  </p>
                }
                {selectedDetailedCauseFix && selectedDetailedCauseFix.length > 0 && 
                  <p>
                    <strong>Fix Instruction :</strong>
                    <div
                      dangerouslySetInnerHTML={{ __html: selectedDetailedCauseFix! }}
                      className="text-justify text-[17px] pl-5 pt-2"
                    />
                  </p>
                }
                <button onClick={closeModal} className="mt-4 p-2 bg-red-500 text-white rounded-md">
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
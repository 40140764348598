import { useEffect, useRef, useState } from "react";
import { SearchLineage } from "./components/SearchLineage";
import { Lineage } from "./Lineage";
import { KPIType } from "./Lineage";
import { useDataGouv } from "./providers/DataGouvProvider";
import { useActiveTabs } from "../../providers";

export const DataLineage = () => {
  const { getEdges, getTables, buSelected } = useDataGouv();
  const [selectedKpi, setSelectedKpi] = useState<string | null>(null);
  const [selectedNode, setSelectedNode] = useState<KPIType | null>(null);
  const { dataLineage } = useDataGouv();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [kpiDefinition ,setKpiDefinition] = useState<string | null>(null)
  const {tabState} = useActiveTabs()

  useEffect(() => {
    if (selectedKpi) {
      const kpiObject = dataLineage && dataLineage[buSelected!].find((elem) => elem.kpi_name === selectedKpi);
      const filePath = kpiObject?.file_path;
      if (filePath) {
        getTables(filePath);
        getEdges(filePath);
      }
      setKpiDefinition(kpiObject?.definition!)
      setSelectedNode(null)
    }
    
  }, [selectedKpi]);

  // useEffect(()=>{
  //   console.log(selectedNode)
  // },[selectedNode])

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(()=>{
    if(buSelected) setSelectedKpi(null); setKpiDefinition(null);setSelectedNode(null)
  },[buSelected, tabState.activeTabId])

  return (
    <div className="relative w-full">
      <div
        ref={scrollContainerRef}
        className="flex flex-col w-full h-full  bg-transparent p-4 rounded-xl items-center text-text-primary-light gap-5"
      >
        <SearchLineage
          selectedKpi={selectedKpi}
          setSelectedKpi={setSelectedKpi}
          selectedNode={selectedNode}
        />
        {selectedKpi && (
          <Lineage
            setSelectedNode={setSelectedNode}
            selectedKpi={selectedKpi}
            scrollContainerRef={scrollContainerRef}
          />
        )}
      </div>
      {/* <button
        onClick={scrollToTop}
        className="absolute bottom-4 right-4 bg-white py-2 px-5 text-sm border border-[#D0D5DD] bg-opacity-80 text-[#344054] rounded-xl focus:outline-none cursor-pointer"
      >
        Scroll to Top
      </button> */}
        <div
          className="flex flex-row w-[100%] gap-5 text-[#344054] h-[150px] rounded-xl py-4 2xl:mt-20 "
        >
        {/* {selectedKpi && !selectedNode &&
          <div className="p-4 text-sm  gap-1 flex w-full max-w-[65%] flex-col rounded-xl bg-[#EFF8FF] shadow-md">
            <p className="font-bold text-[#004289]">{selectedKpi}</p>
            <div className="overflow-y-scroll no-scrollbar">
              <p className="text-[#667085]">{kpiDefinition}</p>
            </div>
          </div>
        } */}
        {selectedNode &&
          <div className="p-4 text-sm  gap-1 flex w-full max-w-[65%] flex-col rounded-xl bg-[#EFF8FF] shadow-md">
            <p className="font-bold text-[#004289]">
              Definition {selectedNode?.data.label} 
              {selectedNode?.data.format && selectedNode?.data.format.length > 0 && ` (${selectedNode?.data.format})`}
            </p>
            <div className="overflow-y-scroll no-scrollbar text-[#667085]">
              <p className="">{selectedNode?.data.description}</p>
            </div>
          </div> 
        }
        {selectedNode && selectedNode.data?.explanation?.length! > 0 &&
          <div className="p-4 text-sm gap-1 bg-white flex max-w-[65%] border border-[#004289] w-full flex-col rounded-md  bg-[#EFF8FF] shadow-md">
            <p className="font-bold text-[#344054]">Explanation</p>
            <div className="overflow-y-scroll no-scrollbar">
              <p className="text-[#667085]">{selectedNode?.data.explanation}</p>
            </div>
          </div> 
        }
      </div>
    </div>
  );
};
import { Ref, useEffect, useState } from "react";

export interface FileStatusRefProps {
  status: {
    loaded: string;
    checkfile: string;
    checkKpis: string;
    processed: string;
  };
}

interface ErrorRef {
  title: string;
  description: string
}
interface FileStatus {
  loaded: string;
  checkfile: string;
  processed: string;
}

interface FileRecord {
  final_filename: string;
  file_type: string;
  status: FileStatus;
  uploaded_by: string;
  date: string;
  file_url?: string;
  error?: ErrorRef
  linked? : boolean
}

export interface ReferentielData {
  [locationCode: string]: FileRecord[];
}

export const useReferentiel = (token: string | null) => {
    const [dataReferentiel, setDataReferentiel] = useState<ReferentielData | null>(null);
    const [loaderReferentielData, setLoaderReferentielData] = useState(false)

    const getReferentielData = async(use_case?: string,asset?: string, date?: string) => {
        if (token === null) return;
        setLoaderReferentielData(true)
        const [month, year] = date ? date.split('/') : [undefined, undefined];
        
        try {
            const response = await fetch(`/api/data_gov/referentiel/data?use_case=${use_case?.toLowerCase()}&file_asset=${asset}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
        
            if (!response.ok) {
              throw new Error(`Erreur HTTP: ${response.status}`);
            }
        
            const data = await response.json();
            setDataReferentiel(data);
        } catch (error) {
            console.error('An error occurred while fetching the ingestion data:', error);
            return {}
        }
        finally{
            setLoaderReferentielData(false)
        }
    }

    const uploadFileRef = async (file_name: string, file: File, buSelected: string, use_case: string) => {
        if (token === null) return;

        const formData = new FormData();
        formData.append('file_name', file_name.replace('_', ''));
        formData.append('file', file);
        formData.append('file_asset', buSelected)
        formData.append('use_case', use_case.toLocaleLowerCase());

        return fetch(`api/data_gov/referentiel/file`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error("Error uploading file:", error);
            return null;
        });
    };

    const uploadFileRefIngestion = async (file_type: string,file: File, buSelected: string,year: string, month: string) => {
      if (token === null) return;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('file_asset', buSelected)
      formData.append('file_type', file_type);

      return fetch(`api/data_gov/referentiel/mapping_file`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      })
      .then((response) => response.json())
      .then((data) => {
          return data;
      })
      .catch((error) => {
          console.error("Error uploading file:", error);
          return null;
      });
  };


    return { dataReferentiel, setDataReferentiel, getReferentielData, loaderReferentielData, uploadFileRef, uploadFileRefIngestion };
};

import React, { useEffect, useState } from "react";
import { FileRow } from "./FileChecksTable";
import { FileData } from "../hooks/useDataIngestion";
import { ButtonIngestion } from "./ButtonIngestion";
import { useDataGouv } from "../providers/DataGouvProvider";
import './animation.css'

interface LinkedFilesProps {
  linkedFiles: FileData[];
  mainFileName: string;
  version?: string;
  openModalRowId: string | null;
  setOpenModalRowId: (id: string | null) => void;
  rowId: string;
}

export const LinkedFileDropdown: React.FC<LinkedFilesProps> = ({ linkedFiles, mainFileName,openModalRowId, setOpenModalRowId,rowId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {buSelected, uploadFileFromDropzoneMultiple,ingestionData} = useDataGouv()
  const [file,setFile] = useState<File | null>(null)
  const [blink, setBlink] = useState<boolean>(false);


  useEffect(() => {
    if (file && linkedFiles.length > 0 && buSelected) {
      console.log("main name:", mainFileName)
      const filesMeta = linkedFiles.map(f => ({ fileType: f.file_type, version: f.version }));
      uploadFileFromDropzoneMultiple({
        bu: buSelected,
        fileAsset: mainFileName,
        filesMeta: filesMeta,
        file: file
      });
    }
  }, [file]);

  const buData = ingestionData![buSelected!] || [];
  const currentFile = buData.find((file) => (file.file_type === mainFileName || file.file_type === 'CAPEX_FORECAST')) || {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
    kpis: null,
    uploaded_by: null,
    date: null,
    check_ref_messages: [],
  };

  useEffect(()=>{
    if(currentFile.status.loaded === 'running'){
      setDropdownOpen(true)
    }
  },[currentFile]) 

  useEffect(() => {
    if (currentFile.status.loaded === 'running' && !file) {
      setBlink(true);
      const timer = setTimeout(() => setBlink(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [currentFile.status.loaded, file]);
  return (
    <>
      <tr className={`${blink ? 'blink-border' : ''}`}>
        <td colSpan={6} onClick={() => setDropdownOpen(!dropdownOpen)} className="py-2 cursor-pointer border-b border-t">
          <div className="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#686868"
              viewBox="0 0 24 24"
              className={`${!dropdownOpen && '-rotate-90'}`}
            >
              <path
                fill="black"
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="text-sm">{mainFileName}</p>
          </div>
        </td>
        <td colSpan={3} className="px-4 py-2 border-b border-t">
          <ButtonIngestion onClick={() => setDropdownOpen(!dropdownOpen)} status={currentFile.status} setFile={setFile} />
        </td>
      </tr>
      {dropdownOpen &&
        linkedFiles.map((fileData, index) => (
          <FileRow 
              key={index} 
              fileType={fileData.file_type}
              version={fileData.version} 
              isDropdown={true} 
              fileLinked={file}
              rowId={`unlinked-${fileData.file_type}-${fileData.version || ''}-${index}`}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
              file_id={fileData.file_id}
           />
        ))}
    </>
  );
};

export const LinkedBudgetDropdown: React.FC<LinkedFilesProps> = ({ linkedFiles, mainFileName,openModalRowId, setOpenModalRowId,rowId }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [blink, setBlink] = useState<boolean>(false);
  const currentFile = {
    status: {
      loaded: "pending",
      checkfile: "pending",
      checkKpis: "pending",
      processed: "pending",
    },
    error: null,
    url: null,
  };

  useEffect(()=>{
    if(linkedFiles && linkedFiles.length > 0){
      const someFileIsLoading = linkedFiles.some(elem => elem.status.loaded === 'running')
      if(someFileIsLoading){
        setDropdownOpen(true)
        setBlink(true);
        const timer = setTimeout(() => setBlink(false), 5000);
        return () => clearTimeout(timer);
      } 
    }
  },[linkedFiles])
  return (
    <>
      <tr className={`${blink ? 'blink-border' : ''}`}>
        <td colSpan={9} onClick={() => setDropdownOpen(!dropdownOpen)} className="py-3 cursor-pointer border-b border-t">
          <div className="flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#686868"
              viewBox="0 0 24 24"
              className={`${!dropdownOpen && '-rotate-90'}`}
            >
              <path
                fill="black"
                fillRule="evenodd"
                d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="text-sm">{mainFileName}</p>
          </div>
        </td>
      </tr>
      {dropdownOpen &&
        linkedFiles.map((fileData, index) => (
          <FileRow 
              key={index} 
              fileType={fileData.file_type}
              version={fileData.version} 
              isDropdown={true}
              isMultiple={true} 
              rowId={`unlinked-${fileData.file_type}-${fileData.version || ''}-${index}`}
              openModalRowId={openModalRowId}
              setOpenModalRowId={setOpenModalRowId}
              file_id={fileData.file_id}
           />
        ))}
    </>
  );
};
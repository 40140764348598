import React, { useState, useEffect } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  Background,
  MarkerType,
  Node,
  Edge,
  NodeProps,
  Position,
  Handle,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useDataGouv } from './providers/DataGouvProvider';
import { InfoIcon } from './icons/Icons';

const decodeText = (text: string): string => {
  try {
      // const textSanitise = text.replace(/\s+/g, '_')
      const utf8Decoder = new TextDecoder('utf-8');
      const encodedBytes = new Uint8Array(Array.from(text).map((char) => char.charCodeAt(0)));
      return utf8Decoder.decode(encodedBytes);
  } catch (e) {
      console.error('Decoding error:', e);
      return text;
  }
};




export type KPIData = {
  label: string;
  explanation?: string;
  description?: string;
  nodeType: 'header' | 'table' | 'kpi';
  backgroundColor?: string;
  textColor?: string;
  format?: string;
};

export type KPIType = Node<KPIData>;

type KPIEdgeType = Edge<{ label: string }>;


const CustomNode: React.FC<NodeProps<KPIType>> = ({ data, selected }) => {
  const baseStyle: React.CSSProperties = {
    textAlign: 'center',
    border: selected ? '2px solid #004289' : '1px solid #d9d9d9',
    zIndex: 1,
  };

  let styleOverride: React.CSSProperties = {};

  if (data.nodeType === 'header') {
    styleOverride = {
      fontWeight: 'bold',
      fontSize: '23px',
      textAlign: 'center',
      backgroundColor: data.backgroundColor,
      color: data.textColor,
      padding: '10px',
      borderRadius: '30px',
      width: '420px',
    };
  } else if (data.nodeType === 'table') {
    styleOverride = {
      fontWeight: 'bold',
      fontSize: '20px',
      textAlign: 'center',
      backgroundColor: '#e6f7ff',
      padding: '8px',
      borderRadius: '30px',
      border: '1px solid #3498db',
      width: '420px',
    };
  } else if (data.nodeType === 'kpi') {
    styleOverride = {
      fontSize: '20px',
      textAlign: 'center',
      backgroundColor: '#ffffff',
      padding: '6px',
      borderRadius: '4px',
      border: '1px solid #d9d9d9',
      width: '420px',
    };
  }

  const finalStyle: React.CSSProperties = {
    ...baseStyle,
    ...styleOverride,
  };

  return (
    <div style={finalStyle}>
      <Handle
        id="left-source"
        type="source"
        position={Position.Left}
        style={{ top: '50%' }}
      />
      <Handle
        id="right-source"
        type="source"
        position={Position.Right}
        style={{ top: '50%' }}
      />
      <Handle
        id="left-target"
        type="target"
        position={Position.Left}
        style={{ top: '50%' }}
      />
      <Handle
        id="right-target"
        type="target"
        position={Position.Right}
        style={{ top: '50%' }}
      />
      <div>{data.label}</div>
    </div>
  );
};

function isInLastColumn(nodeId: string, lastCol: string | null): boolean {
  if (!lastCol) return false;
  const cleanedLastCol = lastCol.replace(/\s+/g, '_'); 
  const [columnPart] = nodeId.split('|'); 
  return columnPart === cleanedLastCol;
}

interface LineageProps {
  selectedKpi: string | null;
  setSelectedNode: React.Dispatch<React.SetStateAction<KPIType | null>>;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

export const Lineage: React.FC<LineageProps> = ({
  selectedKpi,
  setSelectedNode,
  scrollContainerRef,
}) => {
  const { nodes: backendNodes, edges: backendEdges } = useDataGouv();

  const [nodes, setNodes] = useState<KPIType[]>([]);
  const [originalEdges, setOriginalEdges] = useState<KPIEdgeType[]>([]);
  const [displayedEdges, setDisplayedEdges] = useState<KPIEdgeType[]>([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

  const [adjacencyList, setAdjacencyList] = useState<Map<string, string[]>>(new Map());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [lastColumnName, setLastColumnName] = useState<string | null>(null);


  useEffect(() => {
    if (!backendNodes) return;
    
    const columns = Object.keys(backendNodes);
    const lastCol = columns[columns.length - 1];
    setLastColumnName(lastCol);

    const createNodes = () => {
      const newNodes: KPIType[] = [];
      const globalShift = -300;
      let xPosition = 0;

      const visualMappings: Record<
        string,
        { displayName: string; backgroundColor: string; textColor: string }
      > = {
        PowerBI: {
          displayName: 'Power BI Tables',
          backgroundColor: '#F2C80F',
          textColor: 'black',
        },
        'PowerBI Measures': {
          displayName: 'Power BI KPIs',
          backgroundColor: '#2F8D79',
          textColor: 'white',
        },
        GoogleBigQuery: {
          displayName: 'GCP Tables',
          backgroundColor: '#4081EC',
          textColor: 'black',
        },
      };

      Object.entries(backendNodes).forEach(([columnName, tables]) => {
        let yPosition = 100 + globalShift;
        const columnWidth = 450;

        const visualMapping = visualMappings[columnName] || {
          displayName: columnName.replace(/_/g, ' '),
          backgroundColor: '#585858',
          textColor: 'white',
        };
        const { displayName, backgroundColor, textColor } = visualMapping;

        const headerNodeId = columnName.replace(/\s+/g, '_');
        newNodes.push({
          id: headerNodeId,
          type: 'customNode',
          position: { x: xPosition + columnWidth / 2 - 100, y: 0 + globalShift },
          data: {
            label: displayName,
            nodeType: 'header',
            backgroundColor,
            textColor,
          },
        });

        Object.entries(tables).forEach(([tableName, kpis]) => {
          const cleanedTableName = tableName.replace(/\s+/g, '_');
          const tableNodeId = `${columnName.replace(/\s+/g, '_')}|${cleanedTableName}`;

          newNodes.push({
            id: tableNodeId,
            type: 'customNode',
            position: { x: xPosition + columnWidth / 2 - 100, y: yPosition },
            data: {
              label: tableName,
              nodeType: 'table',
            },
          });

          const sortedKpis = [...(kpis as any[])].sort((a, b) => {
            const labelA = a.kpi.toLowerCase();
            const labelB = b.kpi.toLowerCase();
            return labelA.localeCompare(labelB);
          });

          sortedKpis.forEach((kpiInfo, kpiIndex) => {
            const cleanedKpiName = kpiInfo.kpi.replace(/\s+/g, '_');
            
            const kpiNodeId = `${columnName.replace(/\s+/g, '_')}|${cleanedTableName}|${cleanedKpiName}`;

            newNodes.push({
              id: kpiNodeId,
              type: 'customNode',
              position: {
                x: xPosition + columnWidth / 2 - 95,
                y: yPosition + (kpiIndex + 1) * 60,
              },
              data: {
                label: columnName === 'PowerBI Measures' ? (kpiInfo.remapping_name ? kpiInfo.remapping_name : decodeText(kpiInfo.kpi)) : decodeText(kpiInfo.kpi),
                explanation: kpiInfo.explanation,
                description: kpiInfo.description,
                nodeType: 'kpi',
                format: kpiInfo.format
              },
            });
          });

          yPosition += Math.max((kpis as any[]).length * 60 + 60, 150);
        });

        xPosition += columnWidth + 50;
      });

      setNodes(newNodes);
    };

    createNodes();
  }, [backendNodes]);

  useEffect(() => {
    if (!backendEdges) return;

    const allEdges: KPIEdgeType[] = [];

    let edgeCounter = 0;

    Object.entries(backendEdges).forEach(([_, tables]) => {
      Object.entries(tables).forEach(([__, edgeList]) => {
        (edgeList as any[]).forEach((edge) => {
          let sourceNodeId: string;
          if (
            edge.source.column.replace(/\s+/g, '_') === 'PowerBI' &&
            edge.source.table.replace(/\s+/g, '_') === 'Measures'
          ) {
            sourceNodeId = `PowerBI_Measures|Traffic|${edge.source.kpi.replace(
              /\s+/g,
              '_'
            )}`;
          } else {
            sourceNodeId = `${edge.source.column.replace(/\s+/g, '_')}|${edge.source.table.replace(
              /\s+/g,
              '_'
            )}|${edge.source.kpi.replace(/\s+/g, '_')}`;
          }

          let targetNodeId: string;
          if (
            edge.target.column.replace(/\s+/g, '_') === 'PowerBI' &&
            edge.target.table.replace(/\s+/g, '_') === 'Measures'
          ) {
            targetNodeId = `PowerBI_Measures|Traffic|${edge.target.kpi.replace(
              /\s+/g,
              '_'
            )}`;
          } else {
            targetNodeId = `${edge.target.column.replace(/\s+/g, '_')}|${edge.target.table.replace(
              /\s+/g,
              '_'
            )}|${edge.target.kpi.replace(/\s+/g, '_')}`;
          }

          const isSameColumn = sourceNodeId.split('|').slice(0,2).join('|') === targetNodeId.split('|').slice(0,2).join('|') ;
          const isPredict = selectedKpi?.toLowerCase().includes('predict');

          const edgeType = isSameColumn || isPredict ? 'step' : '';

          const sourceHandle = isSameColumn ? 'left-source' : 'right-source';
          const targetHandle = 'left-target';

          const baseEdgeId = `edge-${sourceNodeId}-${targetNodeId}`;
          let finalEdgeId = baseEdgeId;
          if (allEdges.some((existingEdge) => existingEdge.id === finalEdgeId)) {
            edgeCounter += 1;
            finalEdgeId = `${baseEdgeId}-${edgeCounter}`;
          }

          allEdges.push({
            id: finalEdgeId,
            source: sourceNodeId,
            target: targetNodeId,
            type: edgeType,
            data: { label: `${edge.source.kpi} → ${edge.target.kpi}` },
            style: { stroke: '#004289', strokeWidth: 3, zIndex: 9999 },
            markerEnd: { type: MarkerType.ArrowClosed, color: 'black' },
            animated: false,
            sourceHandle,
            targetHandle,
          });
        });
      });
    });

    setOriginalEdges(allEdges);

    const adjList = new Map<string, string[]>();
    allEdges.forEach((e) => {
      if (!adjList.has(e.source)) adjList.set(e.source, []);
        adjList.get(e.source)!.push(e.target);
      });
      
      setAdjacencyList(adjList);
    }, [backendEdges, selectedKpi]);

    const recenterGraph = () => {
      if (reactFlowInstance) {
        reactFlowInstance.fitView(0.4);
      }
    };

    const traverseGraph = (
      startNodeId: string
    ): { nodes: Set<string>; edges: Set<string> } => {
      const visitedNodes = new Set<string>();
      const visitedEdges = new Set<string>();
      const stack = [startNodeId];

      while (stack.length > 0) {
        const current = stack.pop()!;
        if (visitedNodes.has(current)) continue;
        visitedNodes.add(current);

        const neighbors = adjacencyList.get(current) || [];
        neighbors.forEach((neighbor) => {
          const edgeId = `edge-${current}-${neighbor}`;
          visitedEdges.add(edgeId);
          if (!visitedNodes.has(neighbor)) {
            stack.push(neighbor);
          }
        });
      }

    return { nodes: visitedNodes, edges: visitedEdges };
  };

  const handleNodeClick = (_: React.MouseEvent, clickedNode: KPIType) => {
    const { edges: visitedEdgeIds } = traverseGraph(clickedNode.id);

    const edgesToDisplay = originalEdges.filter((e) => visitedEdgeIds.has(e.id));

    setDisplayedEdges([]);
    setTimeout(() => setDisplayedEdges(edgesToDisplay), 0);

    const explanation = clickedNode.data.explanation || 'No explanation available.';
    setModalContent(explanation);
    setIsModalOpen(true);
    setSelectedNode(clickedNode);
  };

  const handlePaneClick = () => {
    setDisplayedEdges([]);
    setIsModalOpen(false);
  };

  const getBoundingBox = (currentNodes: KPIType[]): [[number, number], [number, number]] => {
    let minX = Infinity,
      maxX = -Infinity,
      minY = Infinity,
      maxY = -Infinity;

    currentNodes.forEach((node) => {
      const { x, y } = node.position;
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y);
    });

    return [
      [minX - 1000, minY - 1000],
      [maxX + 1000, maxY + 1000],
    ];
  };

  const translateExtent = getBoundingBox(nodes);

  useEffect(() => {
    if (!selectedKpi) {
      setNodes([]);
      setOriginalEdges([]);
      setDisplayedEdges([]);
      return;
    }
  
    if (reactFlowInstance && nodes.length > 0 && adjacencyList.size > 0) {
      // Recenter graph
      recenterGraph();
  
      const columns = Object.keys(backendNodes!);
      if (columns && columns.length > 0) {
        const firstColumnName = columns[0];
        const cleanedFirstColumnName = firstColumnName.replace(/\s+/g, '_');
  
        const firstKpiNode = nodes.find((node) => {
          if (node.data.nodeType !== 'kpi') return false;
          const [colPart] = node.id.split('|');
          return colPart === cleanedFirstColumnName;
        });
  
        if (firstKpiNode) {
          handleNodeClick({} as React.MouseEvent, firstKpiNode);
        }
      }
    }
  }, [selectedKpi, reactFlowInstance, nodes, adjacencyList, backendNodes]);  

  return (
    <div className="h-full w-full relative h-[250px] lg:h-[470px]">
      <div className="flex flex-row w-full justify-between">
        <button
          onClick={recenterGraph}
          className="bg-transparent py-2 px-3 text-sm border border-[#D0D5DD] bg-opacity-30 text-[#344054] rounded-xl focus:outline-none cursor-pointer"
        >
          Recenter Graph
        </button>
        <div className="flex flex-row gap-1">
          <InfoIcon />
          <p className="text-[#344054] font-trenda">Click and Drag to move the graph.</p>
        </div>
      </div>

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={displayedEdges}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          translateExtent={translateExtent}
          onInit={setReactFlowInstance}
          nodesDraggable={false}
          elementsSelectable={false}
          panOnScroll={false}
          panOnDrag={true}
          zoomOnPinch={true}
          fitView
          preventScrolling={false}
          onWheel={(event) => {
            if (event.ctrlKey) {
              event.stopPropagation();
            } else {
              event.preventDefault();
            }
          }}
          nodeTypes={{ customNode: CustomNode }}
        >
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

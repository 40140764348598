import React, { useEffect, useState } from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import { useDataGouv } from '../providers/DataGouvProvider';


export const Calendar: React.FC= () => {
  const {setMonthYearSelected, loaderIngestionData, buSelected} = useDataGouv()
  const [nextYear, setNextYear] = useState('')

  const generateMonths = (current: Date): Date[] => {
    const months = [];
    for (let i = 3; i >= 0; i--) {
      const month = subMonths(current, i);
      months.push(month);
    }
    return months;
  };  

  const currentMonth = new Date();
  const [currentStartDate, setCurrentStartDate] = useState(currentMonth);
  const months = generateMonths(currentStartDate);
  const [selectedDate, setSelectedDate] = useState<Date | null>(months[3]);

  useEffect(() => {
    const currentMonthYear = format(currentMonth, 'MM/yyyy');
    // console.log(currentMonthYear)
    const yearAfter = (currentMonth.getFullYear() + 1).toString();
    // console.log(yearAfter)
    setMonthYearSelected(currentMonthYear); 
    setNextYear(yearAfter);
  }, [buSelected]);
  

  // const handlePrevClick = () => {
  //   const newStartDate = subMonths(currentStartDate, 4);
  //   setCurrentStartDate(newStartDate);
  // };

  // const handleNextClick = () => {
  //   const newStartDate = addMonths(currentStartDate, 4);
  //   setCurrentStartDate(newStartDate);
  // };

  return (
    <div className="flex w-full text-sm gap-1">
      {/* <button
        className="text-gray-600 bg-white p-0.5 border border-[#EEEEEE] rounded-tl-md rounded-bl-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="rotate-90"
          width="18"
          height="18"
          fill="black"
          viewBox="0 0 24 24"
        >
          <path
            fill="black"
            fillRule="evenodd"
            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
          ></path>
        </svg>
      </button> */}
      <div className="flex overflow-x-auto no-scrollbar">
        {months.map((month, index) => (
          <div key={index} className="">
            <div
              onClick={() => {
                if(loaderIngestionData) return;
                setSelectedDate(month);
                const monthYear = format(new Date(month), 'MM/yyyy');
                setMonthYearSelected(monthYear);
              }}
              className={`cursor-pointer flex items-center text-[#004289] justify-center w-[120px] text-sm rounded-md ${
                selectedDate?.getMonth() === month.getMonth() && selectedDate.getFullYear() === month.getFullYear() ? 'bg-[#ECF5FF] ' : 'bg-white'
              } px-1 py-2 border border-[#EEEEEE]`}
            >
              {format(month, 'MMMM yyyy')}
            </div>
          </div>
        ))}
        {nextYear &&
          <div 
            onClick={()=>{
              if(loaderIngestionData) return
              const newDate = new Date(parseInt(nextYear), 0);
              setSelectedDate(newDate)
              setMonthYearSelected(format(newDate, 'MM/yyyy'));
            }}
            className={`cursor-pointer ${
              selectedDate?.getFullYear() === parseInt(nextYear) ? 'bg-[#ECF5FF] ' : 'bg-white'
            } flex items-center text-[#004289] justify-center w-[120px] text-sm rounded-md px-1 py-2 border border-[#EEEEEE]`}>
            
            {nextYear}
          </div>
        }
      </div>

      {/* <button
        className="text-gray-600 bg-white p-0.5 border border-[#EEEEEE] rounded-tr-md rounded-br-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="-rotate-90"
          width="18"
          height="18"
          fill="black"
          viewBox="0 0 24 24"
        >
          <path
            fill="black"
            fillRule="evenodd"
            d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414"
            clipRule="evenodd"
          ></path>
        </svg>
      </button> */}
    </div>
  );
};
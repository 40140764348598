import { useEffect, useState } from "react";
import { useDataLineage } from "../hooks/useDataLineage";
import { useAuth } from "../../../providers";
import { useEdges } from "@xyflow/react";
import { useDataGouv } from "../providers/DataGouvProvider";
import { KPIType } from "../Lineage";
import { SearchIcon } from "../icons/Icons";

interface SquareKpiProps {
    definition: string;
    kpi_name: string;
    onClick: () => void;
    selectedKpi: string | null
}

const SquareKpi: React.FC<SquareKpiProps> = ({ definition, kpi_name, onClick, selectedKpi }) => {
    // useEffect(()=>{
    //     console.log(selectedKpi, kpi_name)
    // },[selectedKpi])
    return (
        <div className={`w-fit flex cursor-pointer items-center justify-center ${selectedKpi === kpi_name ? 'bg-[#EFF8FF] text-[#004289]' : 'bg-[#F2F4F7] text-[#344054]'}  rounded-2xl`}onClick={onClick}>
            <div className="text-center px-3 py-1">
                <h3 className="text-xs">{kpi_name}</h3>
                {/* <p className="text-sm text-gray-600">{definition}</p> */}
            </div>
        </div>
    );
};

interface SearchLineageProps {
    selectedKpi: string| null;
    setSelectedKpi:  React.Dispatch<React.SetStateAction<string | null>>
    selectedNode: KPIType | null
}

export const SearchLineage:React.FC<SearchLineageProps> = ({selectedKpi, setSelectedKpi, selectedNode}) => {
    const [input, setInput] = useState<string | null>(null);
    const {dataLineage, nodes, buSelected, loaderLineageData, useCase} = useDataGouv()
    const [selectedDefinition, setSelectedDefinition] = useState<string | null>(null);


    const filteredData = input
        ? dataLineage?.[buSelected!]?.filter((item) =>
            item.use_case.toLocaleLowerCase() === useCase?.toLocaleLowerCase() && item.kpi_name.toLowerCase().includes(input.toLowerCase())
            ) || []
        : dataLineage?.[buSelected!]?.filter(item => item.use_case.toLocaleLowerCase() === useCase?.toLocaleLowerCase()) || [];


    return (
        <div className="flex flex-col w-full justify-start text-black -mt-3">
            <div className="flex flex-row items-center justify-between gap-2 mb-2 p-2 bg-white border-[#D0D5DD rounded-md">
                <SearchIcon/>
                <input
                    className="w-full text-md outline-none placeholder-[#667085]"
                    placeholder="Search"
                    onChange={(e) => setInput(e.target.value)}
                    value={input!}
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#666666"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="cursor-pointer transform transition-transform duration-300 hover:rotate-90"
                    onClick={() => {setInput("");setSelectedDefinition(null);setSelectedKpi(null)}}
                >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </div>
            <div className="mt-4 bg-white rounded-xl p-4 flex flex-col gap-3 flex-wrap">
        {loaderLineageData ? (
            <div className="text-center text-[#344054]">Loading...</div>
                ) : filteredData.length === 0 ? (
                <div className="text-center text-[#344054]">
                    The datalineage module is not available for "{buSelected!} - {useCase}"
                </div>
                ) : (
                <>
                    <div className="text-[#344054]">
                    <h1 className="font-medium">What are you looking for?</h1>
                    </div>
                    <div className="flex flex-row gap-3 flex-wrap">
                    {filteredData.map((item, index) => (
                        <SquareKpi
                        key={index}
                        kpi_name={item.kpi_name}
                        definition={item.definition}
                        onClick={() => {
                            setSelectedDefinition(item.definition);
                            setSelectedKpi(item.kpi_name);
                        }}
                        selectedKpi={selectedKpi}
                        />
                    ))}
                    </div>
                </>
                )}
            </div>
            {/* <div className="flex flex-col">
                {selectedDefinition && (
                    <div className="mt-6 p-3 bg-gray-100 rounded-md shadow-md w-full gap-1 flex flex-col">
                        <p className="font-bold underline">{selectedKpi}</p>
                        <p className="text-gray-700">{selectedDefinition}</p>
                    </div>
                )}
                {selectedNode && selectedNode.data.explanation?.length! > 0 && (
                    <div className="mt-6 p-3 bg-gray-100 rounded-md shadow-md w-full gap-1 flex flex-col">
                        <p className="font-bold underline">{selectedNode.data.label}</p>
                        <p className="text-gray-700">{selectedNode.data.explanation}</p>
                    </div>
                )}
            </div>  */}
        </div>
    );
};
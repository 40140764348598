import { useCallback } from 'react';
import { useChatGPT } from "../providers";
import { v4 as uuidv4 } from 'uuid';

export const useNewSession = () => {
    const { gptConfig, setHomeStatus } = useChatGPT();

    const createNewSession = useCallback(() => {
        // On vérifie s’il existe déjà une session vide
        const emptySession = Object.values(gptConfig.securedGPTHistory).find(
            session => session.conversation.length === 0
        );
        
        if (emptySession) {
            // Si oui, on utilise cette session vide
            const existingSessionId = emptySession.session_id;
            gptConfig.setSelectedChatId(existingSessionId);
        } else {
            // Sinon, on crée une nouvelle session
            const newSessionId = uuidv4();
            const date = new Date();
            const isoString = date.toISOString();
            const formattedDate = isoString.replace(/\.\d{3}Z$/, '+00:00');

            gptConfig.setSecuredGPTHistory(prevHistory => ({
                ...prevHistory,
                [newSessionId]: {
                    session_id: newSessionId,
                    title: "New chat...",
                    data_name: null,
                    data_type: 'text',
                    conversation: [],
                    timestamp: formattedDate,
                    total_pages: 0,
                    additional_time: 0
                }
            }));
            gptConfig.setSelectedChatId(newSessionId);

            if (gptConfig.mDModeActive) {
                // Logique supplémentaire si nécessaire
            }
        }

        // Désactivation du mode MD et mise à jour du status Home
        gptConfig.setMDModeActive(false);
        setHomeStatus(false);
    }, [gptConfig, setHomeStatus]);

    return createNewSession;
};

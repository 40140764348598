import { useNewSession } from "../hooks/useNewSession";
import { WriteIcon } from "./Icon";

export const NewChatBtn = () => {
    const createNewSession = useNewSession();

    return (
        <div 
            onClick={createNewSession}
            className="text-text-primary-light group flex cursor-pointer items-center rounded-lg py-1.5 px-2 hover:bg-gray-100"
        >
            <WriteIcon />
        </div>
    );
};
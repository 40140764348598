import React, { useEffect, useState, useRef } from 'react';
import { useActiveTabs, useAuth } from '../../providers';
import { LoaderComponent, LoaderSDH } from '../../components';
import { useStreamSession } from '../../hooks/useStreamSession';

export const AIFactory = ({ appName, id }: { appName: string, id: number }) => {
  const [streamlitUrl, setStreamlitUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { token } = useAuth();
  const { closeTab } = useActiveTabs();

  useEffect(() => {
    if (token) {
      const awakeStreamlit = async () => {
        try {
          const pingResponse = await fetch(`/api/streamlit/ping/single`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              appName: appName
            }),
          });

          if (pingResponse.ok) {
            const response = await fetch(`/api/streamlit/url?appName=${appName}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });

            const data = await response.json();
            const iframeUrl = data.url + "&embed=true&embed_options=light_theme";
            setStreamlitUrl(iframeUrl);
            setLoading(false);
          } else {
            console.error(`Le ping a échoué avec le statut ${pingResponse.status}`);
            setLoading(false);
            closeTab(id);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL Streamlit :', error);
          setLoading(false);
          closeTab(id);
        }
      };

      awakeStreamlit();
    }
  }, [token]);

  const [sessionId, setSessionId] = useState<string | null>(null);
  const sessionState = useStreamSession(sessionId, token);

  // Extract session ID from URL
  useEffect(() => {
    if (streamlitUrl) {
      try {
        const urlObj = new URL(streamlitUrl);
        const uuid = urlObj.searchParams.get("uuid");
        if (uuid) {
          setSessionId(uuid);
          console.log('Session ID set:', uuid);
        }
      } catch (error) {
        console.error('Error parsing Streamlit URL:', error);
      }
    }
  }, [streamlitUrl]);

  // Handle session expiration
  useEffect(() => {
    console.log('Session state changed:', sessionState); // Debug log

    const handleSessionEnd = async () => {
      if (!sessionId || !token) return;

      if (sessionState.status === 'not_found' ||
        sessionState.status === 'expired' ||
        sessionState.status === 'connection_closed') {

        console.log('Session ending with status:', sessionState.status); // Debug log

        try {
          console.log('Attempting to delete session:', sessionId); // Debug log
          const response = await fetch(`/api/streamlit/delete-session/${sessionId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error(`Delete session failed: ${response.status}`);
          }

          console.log('Session deleted successfully'); // Debug log
        } catch (error) {
          console.error('Error deleting session:', error);
        } finally {
          console.log('Cleaning up session...'); // Debug log
          setSessionId(null);
          closeTab(id);
        }
      }
    };

    handleSessionEnd();
  }, [sessionState.status, sessionId, token, closeTab, id]);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <LoaderComponent loadingConfig={loading}>
        <></>
      </LoaderComponent>
      {streamlitUrl && (
        <iframe
          ref={iframeRef}
          src={streamlitUrl}
          style={{ height: "100%", width: "100%" }}
          title="Streamlit App"
          className={`border border-gray-300 rounded-md ${loading && "hidden"}`}
          onLoad={() => {
            console.log('Streamlit iframe loaded or reloaded');
          }}
          onError={() => {
            console.error('Streamlit iframe failed to load');
          }}
        />
      )}
    </div>
  );
};

export default AIFactory;

import { useState,useEffect } from "react"
import { SelectOptions, SelectOptionsDataGouv } from "../DocumentTranslator/SelectedOptions"
import { SummaryBu,SummaryBuProps } from "./SummaryBU"
import { SVGIcon } from "../../assets"
import { useDataGouv } from "./providers/DataGouvProvider"
import { useActiveTabs } from "../../providers"
import { dataGouvernance } from "../../assets"
import { TrafficIcon } from "./icons/Icons"
import React from "react"

export const capitalizeFirstLetter = (str: string): string => {
    if(str === 'HR') return str
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export const DataGouvHome = () => {
    const { dataQuality, buSelected, setBuSelected, dateSelected, setDateSelected, buOptions, dateOptions, setApp, loaderOverview, useCase, setUseCase } = useDataGouv();
    const [summaryData, setSummaryData] = useState<Record<string, any[]>>({});
    const {openTab, tabState} = useActiveTabs()


    const titleToTabMap: Record<string, any> = dataGouvernance.tabs.reduce((map, tab) => {
        map[tab.title] = tab;
        return map;
    }, {} as Record<string, any>);


    useEffect(() => {
        if (50 === tabState.activeTabId) { 
          setUseCase('Overview');
        }
    }, [tabState.activeTabId]);
    

    useEffect(() => {
        if (buSelected && dateSelected && dataQuality?.data?.[dateSelected]?.[buSelected]) {
            const buData = dataQuality.data[dateSelected][buSelected];

            const groupedData: Record<string, any[]> = {};
            Object.keys(buData).forEach((key) => {
                const keyData = buData[key];
                if (Array.isArray(keyData)) {
                    groupedData[key] = keyData.map((item: any) => ({
                        name: item.name,
                        last_updated: item.last_updated,
                        file_not_received: item.file_not_received,
                        file_not_received_messages: item.file_not_received_messages,
                        file_integrity: item.file_integrity,
                        file_integrity_messages: item.file_integrity_messages,
                        data_integrity: item.data_integrity,
                        data_integrity_messages: item.data_integrity_messages,
                        mapping_coverage: item.mapping_coverage,
                        mapping_coverage_messages: item.mapping_coverage_messages,
                    }));
                }
            });
            setSummaryData(groupedData);
        }
        else{
            setSummaryData({})
        }
        //console.log('loader', loaderOverview)
    }, [buSelected, dateSelected, dataQuality,loaderOverview]);

    return (
        <div className="flex flex-col h-full items-center relative">
            <div className="flex flex-col gap-3 items-center w-full">
                <div className="w-full bg-white flex items-center justify-between p-5">
                    <div className="flex flex-row gap-4 items-center w-full justify-end">
                        <p className="text-[#667085] font-medium">BU Selection:</p>
                        <SelectOptionsDataGouv
                            options={buOptions}
                            defaultValue="-"
                            renderOption={(method) => ({ value: method, label: method })}
                            selectedValue={buSelected!}
                            onChange={(e) => setBuSelected(e.target.value)}
                        />
                        <h1 className="text-[#667085] font-medium">Year to Date:</h1>
                        <SelectOptionsDataGouv
                            options={dateOptions}
                            defaultValue="-"
                            renderOption={(method) => ({ value: method, label: method })}
                            selectedValue={dateSelected!}
                            onChange={(e) => setDateSelected(e.target.value)}
                            isCalendar={true}
                        />
                    </div>
                </div>
                {!loaderOverview && 
                    <div className="flex flex-row justify-between items-center w-full p-5">
                        <h1 className="w-full font-trendaSemiBold text-black text-2xl">
                            Data Gouvernance - {(buSelected === 'SSA' && (useCase?.toLocaleUpperCase() === 'FINANCE') ? 'SSA/AMA' : buSelected) || 'Please choose a BU'}
                        </h1>
                    </div> 
                }
            </div>
            {loaderOverview ? (
                <div className="flex justify-center w-full h-full">
                    <p className="text-[#90A7B9] text-2xl font-bold flex items-center">
                        Loading overview data
                        <span className="flex items-end space-x-1 ml-2 h-4">
                            <span className="w-2 h-2 bg-[#90A7B9] rounded-full animate-pulse [animation-delay:0ms]"></span>
                            <span className="w-2 h-2 bg-[#90A7B9] rounded-full animate-pulse [animation-delay:200ms]"></span>
                            <span className="w-2 h-2 bg-[#90A7B9] rounded-full animate-pulse [animation-delay:400ms]"></span>
                        </span>
                    </p>
                </div>
            ) : buSelected && dateSelected && Object.keys(summaryData).length > 0 ? (
                <div className="grid grid-cols-1 gap-5 2xl:gap-8 2xl:grid-cols-2 p-5 w-full items-center justify-center">
                    {Object.keys(summaryData).map((key, index) => {
                        const tab = titleToTabMap[capitalizeFirstLetter(key)];
                        const icon = tab?.icon || <SVGIcon size="50px" icon="TRAFFIC_ICON" />;

                        return (
                            <React.Fragment key={key}>
                                <div>
                                    <SummaryBu
                                        title={capitalizeFirstLetter(key)}
                                        tables={summaryData[key]}
                                        setApp={setApp}
                                        Icon={{ icon: (icon as JSX.Element).props.icon, size: "50px" }}
                                        tab={tab}
                                    />
                                </div>
                                {index === 3 && Object.keys(summaryData).length - 4 >= 1 && (
                                    <div
                                        className="flex items-center justify-center col-span-1 2xl:col-span-2 mt-4 text-center text-sm font-medium w-full"
                                    >
                                        <div className="bg-[#EAECF0] h-[3px] w-full" />
                                        <p className="text-[#90A7B9] px-6 font-bold">
                                            {Object.keys(summaryData).length - 4}{'\u00A0'}more
                                        </p>
                                        <div className="bg-[#EAECF0] h-[3px] w-full" />
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            ) : (
                <div className="flex justify-center items-center w-full h-full">
                    <p className="text-[#90A7B9] text-2xl font-bold">No data available.</p>
                </div>
            )}
        </div>
    );
}
import { useEffect, useState } from "react"

// PermissionCategory.ts
export interface PermissionCategory {
    Ingestion: string[];
    Referentiel: string[];
    Quality: string[];
    Lineage: string[];
}

export interface UserPermissions {
    [asset: string]: PermissionCategory;
}

export const useDataQuality = (token: string | null) =>{
    const [dataQuality, setDataQuality] = useState<any>()
    const [loaderOverview, setLoaderOverview] = useState<boolean>(false)
    const [userPermissions, setUserPermissions] = useState<UserPermissions | null>(null)

    const getOverviewData = async () => {
        setLoaderOverview(true);
        try {
            const response = await fetch(`/api/gouvernance/overview`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setDataQuality(data.overview_data);
            setUserPermissions(data.user_permissions)
        } catch (error) {
            console.error(error);
        } finally {
            setLoaderOverview(false);
        }
    };

    useEffect(()=>{
        if(token === null) return
        getOverviewData()
    },[token])

    const getQualityDetailForBu = async (asset: string, use_case: string, year: number): Promise<any> => {
        if (token === null) return;
    
        try {
            const response = await fetch(`api/gouvernance/quality`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ asset, use_case, year })
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                console.error(`Error: ${response.status} - ${response.statusText}`);
                return null;
            }
    
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Fetch error:', error);
            return null;
        }
    };    

    return {dataQuality, getQualityDetailForBu, loaderOverview, userPermissions}
}
import { useState, DragEvent, useRef, useEffect } from 'react';
import { useAuth, useCredit } from '../../../providers';
import { useChatGPT } from '../providers';
import { Trash2 } from 'lucide-react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { SessionData } from './useChatGPTConfig';



interface BaseContent {
    id: string;
    type: 'text' | 'attachment';
}

interface TextContent extends BaseContent {
    type: 'text';
    text: string | null;
}

interface AttachmentContent extends BaseContent {
    type: 'attachment';
    name: string;
    file?: File;
    file_type: "audio" | "doc" | "html";
    uri: string | null;
    total_pages: number | null;
    additional_time: number | null;
}

type ContentItem = TextContent | AttachmentContent;

type EstimatedTime = {
    totalTime: number
    total_pages: number
    additional_time: number
}

export const allowedTypes = [
    "image/jpeg", "image/jpg", "image/png", "image/webp",
    "video/mp4", "video/webm", "video/mkv", "video/quicktime",
    "application/pdf",
    "audio/mpeg", "audio/mp3", "audio/wav", "audio/webm",
    "audio/x-m4a", "audio/opus", "audio/aac", "audio/flac", "audio/pcm"
];

export const TextComponent: React.FC<{
    content: TextContent;
    onUpdate: (id: string, text: string) => void;
}> = ({ content, onUpdate }) => {
    return (
        <>
            {
                content.text ?
                    <span
                        contentEditable
                        data-id={content.id}
                        onBlur={(e) => onUpdate(content.id, e.currentTarget.textContent || '')}
                        suppressContentEditableWarning
                    >
                        {content.text}
                    </span>
                    :
                    <span className="flex items-center justify-center h-4 w-4">
                        <div className="relative w-full h-full">
                            {/* Outer spinning ring */}
                            <div className="absolute inset-0 rounded-full border-2 border-green-600 border-t-transparent animate-spin" />
                            {/* Inner static circle */}
                            <div className="absolute inset-[3px] rounded-full bg-green-100" />
                        </div>
                    </span>
            }
        </>
    );
};

export const AttachmentComponent: React.FC<{
    content: AttachmentContent;
    onDelete: (id: string) => void;
}> = ({ content, onDelete }) => {
    return (
        <span
            data-id={content.id}
            contentEditable={false}
            className="relative"
            style={{ lineHeight: '3' }}
        >
            <div className="inline-flex overflow-hidden rounded-lg border border-gray-300 justify-between w-fit items-center pr-2">
                <div className="flex flex-row p-1">
                    <div className="relative w-5 shrink-0 overflow-hidden items-center justify-center content-center">
                        <FileIcon
                            extension={content.file_type}
                            type="document"
                            {...defaultStyles[content.file_type == 'audio' ? 'mp3' : content.file_type]}
                        />
                    </div>
                </div>

                <span className="text-xs font-trendaSemiBold">{content.name}</span>
            </div>

            <div
                className="absolute z-40 -top-5 -right-2 trash-div-element cursor-pointer"
                onClick={async (e) => {
                    e.stopPropagation();
                    if (content.uri) {
                        await onDelete(content.id);
                    }
                }}
            >
                {content.uri ? (
                    <div className="flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white hover:bg-red-700 transition-colors">
                        <Trash2 className="w-3 h-3" />
                    </div>
                ) : (
                    <div className="flex items-center justify-center h-4 w-4">
                        <div className="relative w-full h-full">
                            {/* Outer spinning ring */}
                            <div className="absolute inset-0 rounded-full border-2 border-green-600 border-t-transparent animate-spin" />
                            {/* Inner static circle */}
                            <div className="absolute inset-[3px] rounded-full bg-green-100" />
                        </div>
                    </div>

                )}
            </div>
        </span>
    );
};

export interface MultiModalConfig {
    contents: ContentItem[];
    setContents: React.Dispatch<React.SetStateAction<ContentItem[]>>;
    isEmpty: boolean;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    isTranscriptLoading: boolean;
    setIsTranscriptLoading: React.Dispatch<React.SetStateAction<boolean>>;
    editableRef: React.RefObject<HTMLDivElement>;
    mediaRecorderRef: React.RefObject<MediaRecorder | null>;
    mediaStreamRef: React.RefObject<MediaStream | null>;
    fileInputRef: React.RefObject<HTMLInputElement>;
    isRecording: boolean;
    setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
    toggleRecording: () => Promise<void>;
    handleDrop: (e: DragEvent<HTMLDivElement>) => Promise<void>;
    handleInput: () => void;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
    deleteContent: (id: string) => void;
    updateText: (id: string, newText: string) => void;
    handleScrapeUrl: (url: string) => Promise<void>;
    estimatedTime: EstimatedTime
}


export const useMultiModalConfig = (): MultiModalConfig => {
    const { token } = useAuth()
    const { gptInput, gptConfig } = useChatGPT()
    const [contents, setContents] = useState<ContentItem[]>([]);
    const [isEmpty, setIsEmpty] = useState(true);
    const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
    const editableRef = useRef<HTMLDivElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const mediaStreamRef = useRef<MediaStream | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState<EstimatedTime>({
        totalTime: 0,
        total_pages: 0,
        additional_time: 0
    });


    useEffect(() => {
        const total = calculateTotalTime(contents, gptConfig.securedGPTHistory[gptConfig.selectedChatId])
        setEstimatedTime(total)
    }, [contents, gptConfig.selectedChatId])

    // Add new text content
    const addText = (id: string) => {
        const newText: TextContent = {
            id,
            type: 'text',
            text: null
        };
        setContents(prev => [...prev, newText]);
        setIsEmpty(false);
    };

    const toggleRecording = async () => {
        if (!isRecording) {
            // Start recording
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const recorder = new MediaRecorder(stream);
                const chunks: Blob[] = [];

                recorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        chunks.push(event.data);
                    }
                };

                recorder.onstop = async () => {
                    const blob = new Blob(chunks, { type: 'audio/mp3' });
                    const file = new File([blob], `recording-${Date.now()}.mp3`, { type: 'audio/mp3' });

                    const id_text = Math.random().toString(36).substr(2, 9);
                    addText(id_text)
                    await transcriptAttachments(file, id_text)

                    // Stop all tracks to release the microphone
                    if (mediaStreamRef.current) {
                        mediaStreamRef.current.getTracks().forEach(track => track.stop());
                        mediaStreamRef.current = null;
                    }
                };

                recorder.start();
                mediaRecorderRef.current = recorder;
                mediaStreamRef.current = stream;
                setIsRecording(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        } else {
            // Stop recording
            if (mediaRecorderRef.current) {
                if (isEmpty)
                    setIsEmpty(false);
                mediaRecorderRef.current.stop();
                mediaRecorderRef.current = null;
                setIsRecording(false);
            }
        }
    };

    const handleInput = () => {
        const content = editableRef.current?.innerText;
        const contentWithoutNewlines = content?.replace(/\n/g, '');
        if (contentWithoutNewlines?.length === 0 && editableRef.current) {
            editableRef.current.innerText = contentWithoutNewlines;
        }
        setIsEmpty(contentWithoutNewlines?.length === 0);
    };

    const updateText = (id: string, newText: string) => {
        setContents(prev => prev.map(content =>
            content.type === 'text' && content.id === id
                ? { ...content, text: newText }
                : content
        ));
    };

    // Add new attachment
    const addAttachment = (attachment: AttachmentContent) => {
        setContents(prev => [...prev, attachment]);
        setIsEmpty(false);
    };

    const updateAttachment = (id: string, uri: string, total_pages: number, additional_time: number) => {
        setContents(prev => prev.map(content =>
            content.type === 'attachment' && content.id === id
                ? { ...content, uri: uri, total_pages: total_pages, additional_time: additional_time }
                : content
        ));
    };

    const deleteContent = async (id: string) => {

        const attachment = contents.find(item => 
            item.type === 'attachment' && item.id === id
        ) as AttachmentContent | undefined;
        if (attachment?.uri) {
            const response = await fetch('/api/multidocs_chat/delete', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ gs_link: attachment.uri })
            });

            const responseData = await response.json();

            if (response.ok) {
                setContents(prev => prev.filter(content => content.id !== id));
            } else {
                console.log(`Failed to delete pending entry: ${response.statusText}`)
            }
        } else {
            setContents(prev => prev.filter(content => content.id !== id));
        }
        
    };

    const uploadURL = async (attachment: AttachmentContent) => {
        const formData = new FormData();
        formData.append('sessionId', gptConfig.selectedChatId);
        formData.append('url', attachment.name);

        try {
            const response = await fetch('/api/multidocs_chat/scrape-url', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                const { uri, total_pages, additional_time } = await response.json(); // Supposons que `uri` soit dans la réponse JSON
                updateAttachment(attachment.id, uri, total_pages, additional_time);
            } else {
                await deleteContent(attachment.id);
            }
        } catch (error) {
            await deleteContent(attachment.id);
        }
    };

    const createAttachments = (files: File[]): AttachmentContent[] => {
        return files.map(file => {
            const id_file = Math.random().toString(36).substr(2, 9);
            return {
                id: id_file,
                type: 'attachment',
                name: file.name,
                file: file,
                file_type: file.type.startsWith('audio/') ? 'audio' : 'doc',
                uri: null,
                total_pages: null,
                additional_time: null
            };
        });
    };

    function estimateGenerationTimeFromPages(totalPages = 0) {
        const referencePoints = [
            [10, 5],
            [30, 10],
            [50, 15],
            [60, 20],
            [100, 25],
            [170, 30],
            [220, 35]
        ];

        // Parcours des points de référence
        for (let i = 0; i < referencePoints.length - 1; i++) {
            const [x1, y1] = referencePoints[i];
            const [x2, y2] = referencePoints[i + 1];

            if (x1 <= totalPages && totalPages <= x2) {
                return Math.round(y1 + (totalPages - x1) * (y2 - y1) / (x2 - x1));
            }
        }

        // Si le nombre de pages est supérieur au dernier point de référence
        if (totalPages > referencePoints[referencePoints.length - 1][0]) {
            return Math.round(referencePoints[referencePoints.length - 1][1]);
        }

        // Si le nombre de pages est inférieur au premier point de référence
        if (totalPages < referencePoints[0][0]) {
            const [x1, y1] = referencePoints[0];
            const [x2, y2] = referencePoints[1];
            const rate = (y2 - y1) / (x2 - x1);
            return Math.round(Math.max(0, y1 + (totalPages - x1) * rate));
        }

        return 0;
    }

    function calculateTotalTime(contents: ContentItem[], conv: SessionData): EstimatedTime {
        // Filtrer les contenus de type 'attachment'
        const attachments = contents.filter(
            (content): content is AttachmentContent => content.type === 'attachment'
        );

        // Calculer la somme des additional_time
        const totalAdditionalTime = attachments.reduce((sum, attachment) => {
            return sum + (attachment.additional_time || 0);
        }, 0);

        // Calculer la somme des total_pages
        const totalPages = attachments.reduce((sum, attachment) => {
            return sum + (attachment.total_pages || 0);
        }, 0);

        // Calculer le temps estimé à partir du total des pages
        const estimatedTimeFromPages = estimateGenerationTimeFromPages(totalPages + conv?.total_pages);

        // Retourner la somme du temps estimé et du temps additionnel
        return {
            "totalTime": totalAdditionalTime + estimatedTimeFromPages + conv?.additional_time,
            "total_pages": totalPages + conv?.total_pages,
            "additional_time": totalAdditionalTime + conv?.additional_time
        };
    }


    const focusAtEnd = () => {
        const el = editableRef.current;
        if (!el) return;

        const range = document.createRange();
        const selection = window.getSelection();

        range.selectNodeContents(el);
        range.collapse(false);

        selection?.removeAllRanges();
        selection?.addRange(range);
    };

    const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const files = Array.from(e.dataTransfer.files);
        if (files.length === 0) return;

        const validFiles = files.filter(file => allowedTypes.includes(file.type));
        if (validFiles.length !== files.length) return;

        const newAttachments = createAttachments(files);
        newAttachments.forEach(addAttachment);

        await uploadAttachments(newAttachments);

        focusAtEnd();
    };

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files?.length) return;

        const newAttachments = createAttachments(Array.from(files));
        newAttachments.forEach(addAttachment);

        await uploadAttachments(newAttachments);

        focusAtEnd();

        // Réinitialiser l'input file après le traitement
        if (fileInputRef.current) fileInputRef.current.value = '';
    };


    const handleScrapeUrl = async (url: string) => {
        const id_file = Math.random().toString(36).substr(2, 9);
        const newAttachments: AttachmentContent = {
            id: id_file,
            type: 'attachment',
            name: url,
            file_type: 'html',
            uri: null,
            total_pages: null,
            additional_time: null
        }
        addAttachment(newAttachments)

        await uploadURL(newAttachments);

        focusAtEnd();

        // Réinitialiser l'input file après le traitement
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    const uploadAttachments = async (attachments: AttachmentContent[]) => {
        const uploadPromises = attachments.map(async (attachment) => {
            const formData = new FormData();
            const file = attachment.file;

            formData.append('sessionId', gptConfig.selectedChatId);
            if (file) {
                let duration;
                if (file.type.startsWith('video/') || file.type.startsWith('audio/')) {
                    duration = await getMediaDuration(file);
                }
                formData.append('file', file);
                const lastModifiedDate = new Date(file.lastModified);
                const formattedDate = lastModifiedDate.toISOString().split('T')[0];
                const type = file.type.split('/')
                formData.append('fileInfo', JSON.stringify({
                    size: file.size,
                    sizeInMo: Number((file.size / (1024 * 1024)).toFixed(2)),
                    completeType: file.type,
                    type: type.length > 0 ? type[0] : file.type,
                    name: file.name,
                    lastModified: formattedDate,
                    durationInMinute: duration ? Number((duration / 60).toFixed(2)) : undefined
                }));
            }

            try {
                const response = await fetch('/api/multidocs_chat/upload-file', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (response.ok) {
                    const { uri, total_pages, additional_time } = await response.json(); // Supposons que `uri` soit dans la réponse JSON
                    updateAttachment(attachment.id, uri, total_pages, additional_time);
                } else {
                    await (attachment.id);
                }
            } catch (error) {
                await deleteContent(attachment.id);
            }
        });

        await Promise.all(uploadPromises);
    };

    const getMediaDuration = (file: File): Promise<number> => {
        return new Promise((resolve) => {
            const element = file.type.startsWith('video/')
                ? document.createElement('video')
                : document.createElement('audio');

            element.preload = 'metadata';
            element.onloadedmetadata = () => {
                URL.revokeObjectURL(element.src);
                resolve(element.duration);
            };
            element.src = URL.createObjectURL(file);
        });
    };

    const transcriptAttachments = async (file: File, id_text: string) => {
        const formData = new FormData();
        formData.append('sessionId', gptConfig.selectedChatId);
        formData.append('file', file);
        setIsTranscriptLoading(true)
        try {
            const response = await fetch('/api/multidocs_chat/transcript-file', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData,
            });

            if (response.ok) {
                const { answer } = await response.json();
                updateText(id_text, answer);
            } else {
                await (id_text)
            }
        } catch (error) {
            await deleteContent(id_text)
        }
        finally {
            setIsTranscriptLoading(false)
        }
    };

    return {
        contents,
        setContents,
        isEmpty,
        setIsEmpty,
        isTranscriptLoading,
        setIsTranscriptLoading,
        editableRef,
        mediaRecorderRef,
        mediaStreamRef,
        fileInputRef,
        isRecording,
        setIsRecording,
        toggleRecording,
        handleDrop,
        handleInput,
        handleFileUpload,
        deleteContent,
        updateText,
        handleScrapeUrl,
        estimatedTime
    };
};


import React from "react"
import { useEffect, useState } from "react"
import { useDataGouv } from "./providers/DataGouvProvider"



const AuthorisedBUs: React.FC<{ title: string }> = ({ title }) => {
    const {setBuSelected} = useDataGouv()
    return(
        <div onClick={() => setBuSelected(title)} className="flex w-[120px] rounded-xl items-center justify-center px-4 py-2 bg-[#004289] text-white hover:bg-gray-200 cursor-pointer hover:text-[#004289] hover:font-bold">
          <h1>{title}</h1>
        </div>
    );
}



export const AuthDenied:React.FC<{validBus: string[]}> = ({validBus}) =>{
    const {useCase} = useDataGouv()

    return(
        <div className="flex flex-col w-full bg-white p-5 rounded-xl text-text-primary-light gap-5">
            {validBus && validBus.length > 0 ? <h1 className="text-center">Here are the BUs to which you have access for {useCase}.</h1> : <h1 className="text-center">You do not have any BUs that are authorised for {useCase}.</h1>}
            {validBus && validBus.length > 0 &&
                <div className="flex flex-row flex-wrap gap-4">
                    {validBus.map((elem,index) => (
                        <AuthorisedBUs key={index} title={elem}/>
                    ))}
                </div>
            }
        </div>
    )

}